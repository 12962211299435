//https://help.aliyun.com/document_detail/92883.html#section-mx7-v31-uy7 oss文档

import { getSTS } from '@/api/oss.js';
import sjcl from './sjcl/sjcl.js';
import('./sjcl/sha1.js');
import request from '@/utils/request';

const getHost = () => {
  // if ('release' != wx.getAccountInfoSync().miniProgram.envVersion) {
  //   return 'https://dapianlaile-test.oss-cn-chengdu.aliyuncs.com/'
  // }

  return 'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/';
};

const createPolicy = function () {
  const date = new Date();
  date.setHours(date.getHours() + 1);
  const policyText = {
    expiration: date.toISOString(), // 设置policy过期时间。
    conditions: [
      // 限制上传大小。
      ['content-length-range', 0, 100 * 1024 * 1024]
    ]
  };

  return sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(JSON.stringify(policyText)));
};

const createSignature = function (policy, accessKeySecret) {
  const val = new sjcl.misc.hmac(sjcl.codec.utf8String.toBits(accessKeySecret), sjcl.hash.sha1);
  return sjcl.codec.base64.fromBits(val.encrypt(policy));
};

function uploadFile(file, type = 'image') {
  return new Promise(async (resolve, reject) => {
    if (Object.prototype.toString.call(file) != '[object File]') {
      reject('file is error type');
      return;
    }

    const { data: sts } = await getSTS({});
    let rootPath =
      type == 'video' ? 'APPvideo' : type == 'videoCover' ? 'APPvideocover' : 'APPimage';

    const host = getHost();
    const policy = createPolicy();
    const signature = createSignature(policy, sts.accessKeySecret);
    const ossAccessKeyId = sts.accessKeyId;
    const securityToken = sts.securityToken;
    const key = `${rootPath}/${Date.now()}_${file.name}`;

    const formData = new FormData();
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('OSSAccessKeyId', ossAccessKeyId);
    formData.append('signature', signature);
    formData.append('x-oss-security-token', securityToken);
    formData.append('file', file);

    request({
      url: host,
      method: 'POST',
      data: formData,
      headers: {
        'x-oss-object-acl': 'public-read',
        'Content-Type': 'multipart/form-data'
      },
      rawResponse: true
    })
      .then((res) => {
        if (res.status === 204) {
          resolve(getHost() + '/' + key);
          return;
        }

        reject(res.statusText);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default uploadFile;
