<template>
  <div class="we-chat-style-wrapper">
    <div class="we-chat-style-item" :class="classString"></div>
  </div>
</template>

<script>
export default {
  name: 'WeChatStyleWrapper',
  props: {
    index: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  computed: {
    classString() {
      if (this.index === 1) {
        return 'style1';
      }
      if (this.index === 2) {
        return 'style2';
      }
      if (this.index === 3) {
        return 'style3';
      }
      if (this.index === 4) {
        return 'style4';
      }
      if (this.index === 5) {
        return 'style5';
      }
      if (this.index === 6) {
        return 'style6';
      }
      if (this.index === 7) {
        return 'style7';
      }
      if (this.index === 8) {
        return 'style8';
      }
      return '';
    }
  }
};
</script>

<style scoped lang="less">
.we-chat-style-item {
  width: 80px;
  height: 80px;
  background: #eee;
  margin-bottom: 10px;

  &.style1 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style001.png') no-repeat 0 0;
  }

  &.style2 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style002.png') no-repeat 0 0;
  }

  &.style3 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style003.png') no-repeat 0 0;
  }

  &.style4 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style004.png') no-repeat 0 0;
  }

  &.style5 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style005.png') no-repeat 0 0;
  }

  &.style6 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style006.png') no-repeat 0 0;
  }

  &.style7 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style007.png') no-repeat 0 0;
  }

  &.style8 {
    width: 80px;
    height: 80px;
    background: url('../../assets/images/style008.png') no-repeat 0 0;
  }
}
</style>
