<template>
  <div class="dialog-settings">
    <el-form :model="ruleForm" ref="dialogSettingsForm" label-width="100px">
      <el-form-item label="推荐图片：">
        <el-upload
          action="#"
          list-type="picture-card"
          :on-remove="handleRemoveImage"
          :auto-upload="false"
          accept="image/*"
          :file-list="ruleForm.imgList"
          :on-change="imageChange"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="自定义链接：">
        <el-input v-model="ruleForm.link"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getSettings, saveSettings, resetSettings, uploadImage } from '@/api/smallProgramDialog';

export default {
  props: {
    topicId: {
      typs: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {
        link: '',
        imgList: []
      }
    };
  },
  watch: {
    topicId: {
      immediate: true,
      handler(newVal, _) {
        if (newVal) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    async save(status) {
      try {
        const isHaveImg = this.ruleForm.imgList.length > 0;
        let picUrl = !isHaveImg ? '' : this.ruleForm.imgList[0].url || '';

        if (isHaveImg && this.ruleForm.imgList[0].raw) {
          const uploadImgObj = await uploadImage(this.ruleForm.imgList[0].raw);
          picUrl = uploadImgObj.http;
        }

        status = status || 2;

        const res = await saveSettings({
          data: {
            siteId: 4,
            status,
            topicId: this.topicId,
            banners: [{ siteId: 4, productId: this.ruleForm.link, picUrl, status }]
          }
        });

        if (res) {
          this.$message.success('操作成功');
          return;
        }

        this.$message.error('操作失败');
      } catch (err) {
        console.log(err);
      }
    },
    getDetail() {
      getSettings({
        data: { siteId: 4, topicId: this.topicId }
      })
        .then((res) => {
          if (!Array.isArray(res) || res.length === 0) {
            this.ruleForm.link = '';
            this.ruleForm.imgList = [];
            return;
          }

          this.ruleForm.link = res[0].productId || '';
          const imgRes = res[0].picUrl ? [{ url: res[0].picUrl, name: '', raw: null }] : [];
          this.ruleForm.imgList = imgRes;
        })
        .catch((err) => console.log(err));
    },
    handleRemoveImage(file, _) {
      const index = this.ruleForm.imgList.findIndex((el) => el.name === file.name);
      if (index > -1) this.ruleForm.imgList.splice(index, 1);
    },
    imageChange(_, fileList) {
      this.ruleForm.imgList = fileList;
    },
    resetPreviousData() {
      resetSettings({
        data: {
          siteId: 4,
          topicId: this.topicId
        }
      })
        .then((res) => {
          if (res) {
            this.$message.success('操作成功');
            this.getDetail();
            return;
          }

          this.$message.error('操作失败');
        })
        .catch((err) => console.log(err));
    },
    publish() {
      this.save(1);
    }
  }
};
</script>

<style lang="less" scoped>
.dialog-settings {
  position: relative;

  /deep/ .el-upload-list__item {
    transition: none;
  }
}
</style>
