<template>
  <el-dialog :title="title" :visible="show" width="560px" :before-close="handleCancel">
    <div :loading="loading">
      <el-form label-width="100px" :model="form" :rules="rules" size="small" ref="modalForm">
        <el-form-item label="专题名称：" prop="topicName">
          <el-input v-model="form.topicName" />
        </el-form-item>
        <el-form-item label="排序数字：" prop="sortNum">
          <el-input-number
            v-model="form.sortNum"
            :min="1"
            :step="1"
            step-strictly
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="适用城市：" prop="cityIds">
          <el-button type="text" @click="cityVisible = true">点击选择适用城市</el-button>
          <div class="tags">
            <el-tag
              v-for="(tag, index) in cityNames"
              :key="index"
              closable
              @close="() => handleCityTagClose(index)"
              style="margin-right: 10px; margin-bottom: 10px"
              >{{ tag }}</el-tag
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="createProductType" :loading="inputLoad">确 定</el-button>
    </span>
    <city-mange :visible="cityVisible" :value="form.cityIds" @ok="cityOk" @cancel="cityCancel" />
  </el-dialog>
</template>

<script>
// 产品分类，新增编辑
import omit from 'omit.js';
import { cityProjectSave, cityProjectDet } from '@/api/wechat';
import cityMange from './cityTree.vue';

export default {
  name: 'EditProductType',
  components: {
    cityMange
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    topicId: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateCity = (rule, value, callback) => {
      if (this.cityNames.length === 0) {
        callback(new Error('城市不能为空'));
      } else {
        callback();
      }
    };
    return {
      form: {
        topicId: '',
        topicName: '',
        sortNum: 1,
        cityIds: []
      },
      rules: {
        topicName: [
          { required: true, message: '请输入专题名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        cityIds: [{ required: true, validator: validateCity, trigger: 'change' }]
      },
      cityVisible: false,
      cityNames: [],
      loading: false,
      inputLoad: false
    };
  },
  computed: {
    title() {
      let title = '';
      if (this.topicId === '') {
        title = '新增城市专题';
      } else {
        title = '编辑城市专题';
      }
      return title;
    }
  },
  watch: {
    topicId: {
      handler(val) {
        if (val !== '') {
          this.loading = true;
          cityProjectDet({ data: val }).then((res) => {
            this.loading = false;
            const { topicName, topicId, sortNum, cityIdList } = res;
            this.form.topicName = topicName;
            this.form.topicId = topicId;
            this.form.sortNum = sortNum;
            this.form.cityIds = cityIdList;
            this.cityNames = res.cityList.map((i) => i.cityName);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {},
    edit(data) {},
    cityOk(e) {
      this.form.cityIds = e.map((i) => i.districtId);
      this.cityNames = e.map((i) => i.districtName);
      this.form.cityList = e.map((i) => ({
        productId: this.form.productId,
        cityId: i.districtId,
        cityName: i.districtName
      }));
      this.cityVisible = false;
    },
    cityCancel() {
      this.cityVisible = false;
    },
    handleCityTagClose(index) {
      this.form.cityList = this.form.cityList.filter((_, i) => i !== index);
      this.form.cityIds = this.form.cityIds.filter((_, i) => i !== index);
      this.cityNames = this.cityNames.filter((_, i) => i !== index);
    },
    handleCancel() {
      this.form.topicName = '';
      this.form.topicId = '';
      this.form.sortNum = 1;
      this.form.cityIds = [];
      this.cityNames = [];
      this.$refs.modalForm.resetFields();
      this.$emit('cancel');
    },
    handleOk() {
      this.form.topicName = '';
      this.form.topicId = '';
      this.form.sortNum = 1;
      this.form.cityIds = [];
      this.cityNames = [];
      this.$refs.modalForm.resetFields();
      this.$emit('ok');
    },

    createProductType() {
      console.log(this.form);
      this.$refs.modalForm.validate(async (valid) => {
        if (valid) {
          this.inputLoad = true;
          let addData = null;
          if (this.form.topicId === '') {
            addData = omit(this.form, ['topicId']);
          } else {
            addData = { ...this.form };
          }
          const addParams = { data: addData };
          try {
            let response = null;
            response = await cityProjectSave(addParams);
            this.inputLoad = false;
            if (response) {
              this.$message.success('操作成功');
              this.handleOk();
            } else {
            }
            // if (this.command === '0' || this.command === '1') {
            // } else {
            //   response = await updateProductType(updateDataParams);
            //   this.$message.success('操作成功');
            //   this.handleOk();
            // }
          } catch (err) {}
        }
      });
    }
  }
};
</script>

<style scoped>
.tags {
  max-height: 80px;
  overflow: auto;
}

.tags::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}

.tags::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}

.tags::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
</style>
