<template>
  <el-dialog
    custom-class="set-city-manage"
    title
    :visible.sync="dialogVisible"
    @close="cancel"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
  >
    <div v-loading="loading">
      <el-row>
        <el-col :span="10">
          <div>
            <el-input
              prefix-icon="el-icon-search"
              placeholder="搜索城市名称"
              v-model="filterText"
            ></el-input>
          </div>
          <el-divider />
          <div class="city-tree">
            <el-tree
              :data="data"
              ref="tree"
              default-expand-all
              :filter-node-method="filterNode"
              icon-class="el-icon-arrow-right"
              show-checkbox
              node-key="districtId"
              :props="defaultProps"
              @check-change="selectNode"
              :default-checked-keys="defaultKeys"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="title" :style="data.districtType == 1 ? 'font-weight:600' : ''">
                  {{ data.districtName }}
                </span>
              </span>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="14">
          <p style="margin-left: 30px; margin-bottom: 10px; margin-top: 0; font-weight: 600">
            已选
          </p>
          <div
            class="city-tags"
            style="padding: 10px; padding-top: 0; padding-left: 30px; height: 430px"
          >
            <el-tag
              v-for="city in selectList"
              :key="city.districtId"
              v-show="city.districtType !== 1"
              style="margin-right: 10px; margin-bottom: 10px"
              >#{{ city.districtName }}</el-tag
            >
          </div>
          <div style="text-align: right; margin-top: 15px">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="ok">确认</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { getCityTree } from '@/api/city';
// @ is an alias to /src
const typeList = ['', 'success', 'info', 'warning', 'danger'];
export default {
  name: 'set-city-manage',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: [],
      filterText: '',
      selectList: [],
      total: 0,
      typeList,
      dialogVisible: false,
      loading: false,
      defaultKeys: [],
      defaultProps: {
        label: 'districtName',
        children: 'childs',
        disabled: (data) => data.districtType === 3
      }
    };
  },
  watch: {
    value: {
      handler(val) {
        this.selectList = [];
        if (val && val.length > 0) {
          this.defaultKeys = [...val];
        }
      },
      deep: true
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    visible(val) {
      this.dialogVisible = val;
      this.filterText = '';
      if (val) {
        this.$nextTick(() => {
          console.log(this.value);
          if (this.value) {
            this.$refs.tree.setCheckedKeys(this.value);
          }
          this.selectList = this.$refs.tree.getCheckedNodes();
        });
      }
    }
  },
  methods: {
    ok() {
      this.$emit(
        'ok',
        this.selectList.filter((i) => i.districtType !== 1)
      );
    },
    cancel() {
      this.$emit('cancel');
    },
    filterNode(value, data) {
      if (data.districtName.indexOf(value) !== -1||data.nameCh.indexOf(value) !== -1) {
        return true;
      }
      if (!value) return true;
      return false;
    },
    selectNode() {
      this.selectList = this.$refs.tree.getCheckedNodes();
    },
    changeTree(list) {
      list.forEach((e) => {
        if (e.childs) {
          if (e.districtType === 2) {
            e.childs = null;
          } else {
            this.changeTree(e.childs);
          }
        }
      });
    }
  },

  components: {},
  async created() {
    try {
      this.loading = true;
      const response = await getCityTree();
      this.loading = false;
      this.changeTree(response);
      this.data = [...response];
      this.$nextTick(() => {
        if (this.visible) {
          this.selectList = this.$refs.tree.getCheckedNodes();
        }
      });
    } catch (err) {}
  }
};
</script>
<style lang="less" scoped>
.city-tree {
  height: 400px;
  overflow: auto;
  .custom-tree-node {
    height: auto;
    width: 100%;
    padding-right: 4px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      display: inline-block;
      padding: 3px 0;
    }
    .citys {
      height: auto;
      width: 100%;
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        i {
          z-index: 999 !important;
        }
      }
      .cityitems {
      }
      .cityitems::after {
        content: '';
        clear: both;
      }
      .city {
        padding: 0px 16px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        position: relative;
        float: left;
        margin: 4px 8px;
        .icon {
          position: absolute;
          right: 0px;
          bottom: 0;
        }
      }
      .city:focus {
        background-color: #f5f7fa;
      }
      .city-isSelect {
        border: 1px solid #2861e2;
      }
    }
  }
}
.city-tree::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.city-tree::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.city-tree::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.city-tags {
  overflow: auto;
}
.city-tags::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.city-tags::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.city-tags::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
</style>

<style lang="less">
.set-city-manage {
  .el-collapse-item__header {
    border: 0 !important;
    height: 32px !important;
    line-height: 32px !important;
  }
  .el-collapse {
    border: 0 !important;
  }
  .el-collapse-item__wrap {
    border: 0 !important;
    margin-bottom: 8px;
  }
  .el-dialog__header {
    padding: 0;
  }
  // .el-tree-node__expand-icon {
  //   position: absolute;
  //   right: 0;
  // }
  .el-tree-node__content {
    height: auto !important;
    background-color: #fff !important;
  }
}
</style>
