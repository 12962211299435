<template>
  <section
    class="recommentd-edit-content"
    ref="altitude"
    v-loading="loading"
    @scroll.passive="contentScroll"
  >
    <el-form label-width="180px" :model="form" ref="recommendForm">
      <div v-for="(area, index) in form.recommendDataList" :key="`area${index}`" class="area-item">
        <div class="area-item-title">
          <div
            style="width: 100%; display: flex; align-items: center; justify-content: space-between"
          >
            <span>{{ area.isTab ? '吸顶导航区' : '推荐区' }}</span>
            <i class="el-icon-delete area-delete-icon" @click="deleteArea(area, index)"></i>
          </div>
        </div>
        <div class="area-item-sort">
          <span class="text-black-a" style="font-size: 14px; margin-right: 20px">排序:</span>
          <span
            ><el-input-number size="medium" v-model="area.sortNum" :min="1"></el-input-number
          ></span>
        </div>
        <template v-if="area.isTab">
          <el-button
            v-if="area.isTab"
            size="mini"
            type="primary"
            style="margin-top: 10px; margin-bottom: 10px"
            @click="addChildren(area, index)"
            >增加分组</el-button
          >
          <el-tabs
            v-if="area.isTab && area.childrenList.length"
            v-model="form.recommendDataList[index].currentChildren"
            type="card"
            closable
            @tab-remove="(e) => removeTab(e, area, 1)"
          >
            <el-tab-pane
              :key="childrenIndex"
              v-for="(item, childrenIndex) in form.recommendDataList[index].childrenList"
              :label="item.title"
              :name="String(childrenIndex)"
            >
            </el-tab-pane>
          </el-tabs>
          <div class="area-item-content" style="border-top: none">
            <template v-for="(child, childrenIndex) in area.childrenList">
              <div
                :key="childrenIndex"
                :style="{
                  display: childrenIndex == area.currentChildren ? '' : 'none'
                }"
              >
                <el-form-item
                  :rules="rules.title"
                  :prop="'recommendDataList.' + index + '.childrenList.' + childrenIndex + '.title'"
                  label="主标题："
                  label-width="70px"
                >
                  <el-input
                    placeholder="请输入"
                    v-model="child.title"
                    maxlength="10"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="副标题：" label-width="70px">
                  <el-input
                    placeholder="请输入"
                    v-model="child.subject"
                    maxlength="15"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="排序：" label-width="70px">
                  <el-input-number size="medium" v-model="child.sortNum" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="探索更多：" label-width="70px">
                  <el-select
                    placeholder="请选择"
                    v-model="child.recommendLinkValue"
                    no-data-text="暂无"
                  >
                    <!-- <el-option
                      v-for="(item, index) in 3"
                      :key="index"
                      :label="item"
                      :value="item"
                    ></el-option> -->
                  </el-select>
                </el-form-item>

                <el-button
                  size="mini"
                  type="primary"
                  style="margin-bottom: 10px"
                  @click="addGroup(child)"
                  >增加分类</el-button
                >
                <el-tabs
                  v-if="child.groupList.length"
                  v-model="form.recommendDataList[index].childrenList[childrenIndex].currentGroup"
                  type="card"
                  closable
                  @tab-remove="(e) => removeTab(e, child, 2)"
                >
                  <el-tab-pane
                    :key="grandsonIndex"
                    v-for="(item, grandsonIndex) in form.recommendDataList[index].childrenList[
                      childrenIndex
                    ].groupList"
                    :label="item.recommendAreaGroupName"
                    :name="String(grandsonIndex)"
                  >
                  </el-tab-pane>
                </el-tabs>

                <div class="area-item-content-b">
                  <template v-for="(grandson, grIndex) in child.groupList">
                    <div
                      :key="grIndex"
                      :style="{
                        display: grIndex == child.currentGroup ? '' : 'none'
                      }"
                    >
                      <el-form-item
                        :rules="rules.childrenTitle"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.childrenList.' +
                          childrenIndex +
                          '.groupList.' +
                          grIndex +
                          '.recommendAreaGroupName'
                        "
                        label-width="80px"
                        label="分类名称："
                      >
                        <el-input
                          placeholder="请输入"
                          v-model="grandson.recommendAreaGroupName"
                          maxlength="8"
                          show-word-limit
                        ></el-input>
                      </el-form-item>

                      <!-- <el-form-item label-width="80px" label="排序：">
                        <el-input-number size="medium" v-model="grandson.sortNum"></el-input-number>
                      </el-form-item> -->

                      <el-form-item
                        :rules="rules.composeType"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.childrenList.' +
                          childrenIndex +
                          '.groupList.' +
                          grIndex +
                          '.composeType'
                        "
                        label-width="80px"
                        label="样式库："
                      >
                        <div class="dropdown-container">
                          <input
                            class="input"
                            type="text"
                            v-model="grandson.composeTypeName"
                            @click.stop="
                              (e) => toggleDropdown(e, grandson, index, childrenIndex, grIndex)
                            "
                            placeholder="请选择"
                            readonly="readonly"
                            autocomplete="off"
                          />
                          <i
                            class="dropdown-icon"
                            :class="{
                              'el-icon-arrow-down': !grandson.dropdownVisible,
                              'el-icon-arrow-up': grandson.dropdownVisible
                            }"
                          ></i>
                          <div
                            @click.stop="() => {}"
                            class="dropdown-list"
                            :style="{ top: grandson.top + 'px', left: grandson.left + 'px' }"
                            v-show="grandson.dropdownVisible"
                          >
                            <el-radio-group
                              v-model="grandson.composeTypeName"
                              @change="
                                (e) => handleStyleChange(e, grandson, index, childrenIndex, grIndex)
                              "
                            >
                              <div
                                class="dropdown-item"
                                v-for="(layout, layIndex) in layoutList"
                                :key="layIndex"
                              >
                                <el-image
                                  class="layout-image"
                                  :src="
                                    require(`../../../../assets/images/style${
                                      layoutStyle[layout.dictionaryValue - 1]
                                    }.png`)
                                  "
                                ></el-image>
                                <el-radio
                                  @click.native="radioClick(grandson)"
                                  :label="layout.dictionaryName"
                                  >{{ layout.dictionaryName }}</el-radio
                                >
                              </div>
                            </el-radio-group>
                          </div>
                        </div>
                      </el-form-item>

                      <el-form-item
                        :rules="rules.recommendProduct"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.childrenList.' +
                          childrenIndex +
                          '.groupList.' +
                          grIndex +
                          '.picList'
                        "
                        label-width="80px"
                        label="推荐产品："
                      >
                        <div
                          class="pic-item"
                          v-for="(pic, picIndex) in grandson.picList"
                          :key="picIndex"
                        >
                          <i
                            v-if="
                              grandson.composeType == 6 ||
                              ([1, 2, 5].includes(grandson.composeType) &&
                                grandson.picList.length > 2)
                            "
                            class="el-icon-delete pic-delete-icon"
                            @click="deletePic(pic, index, childrenIndex, grIndex, picIndex)"
                          ></i>

                          <el-form-item
                            :rules="rules.pic"
                            :prop="
                              'recommendDataList.' +
                              index +
                              '.childrenList.' +
                              childrenIndex +
                              '.groupList.' +
                              grIndex +
                              '.picList.' +
                              picIndex +
                              '.picUrl'
                            "
                            label-width="80px"
                            label="产品图片："
                          >
                            <el-upload
                              class="avatar-uploader"
                              accept=".jpg, .png, .jpeg"
                              :action="actionUrl"
                              :show-file-list="false"
                              :on-success="(res, file) => handleAvatarSuccess(res, file, pic)"
                              :before-upload="beforeUpload"
                              list-type="picture-card"
                            >
                              <img
                                v-if="pic.picUrl"
                                :src="pic.picUrl"
                                :style="{ width: '150px', height: '150px' }"
                              />
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="upload-tip">
                              <i class="el-icon-warning"></i>
                              支持 1MB 以内 JPG、PNG 图片，{{
                                [1, 2, 5, 6].includes(grandson.composeType)
                                  ? picTye[grandson.composeType][0]
                                  : picTye[grandson.composeType][picIndex]
                              }}
                            </div>
                          </el-form-item>
                          <el-form-item label-width="80px" label="产品名称：">
                            <el-input
                              placeholder="请输入"
                              v-model="pic.picDec"
                              maxlength="15"
                              show-word-limit
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label-width="80px"
                            :rules="rules.linkType"
                            :prop="
                              'recommendDataList.' +
                              index +
                              '.childrenList.' +
                              childrenIndex +
                              '.groupList.' +
                              grIndex +
                              '.picList.' +
                              picIndex +
                              '.linkType'
                            "
                            label="链接类型："
                          >
                            <el-select v-model="pic.linkType">
                              <el-option label="套系产品ID" :value="1"></el-option>
                              <el-option label="自定义链接" :value="2"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            :rules="rules.link"
                            :prop="
                              'recommendDataList.' +
                              index +
                              '.childrenList.' +
                              childrenIndex +
                              '.groupList.' +
                              grIndex +
                              '.picList.' +
                              picIndex +
                              '.linkValue'
                            "
                            label-width="80px"
                            label="链接："
                          >
                            <el-input placeholder="请输入" v-model="pic.linkValue"></el-input>
                          </el-form-item>
                          <el-form-item
                            :rules="rules.sort"
                            :prop="
                              'recommendDataList.' +
                              index +
                              '.childrenList.' +
                              childrenIndex +
                              '.groupList.' +
                              grIndex +
                              '.picList.' +
                              picIndex +
                              '.sortNum'
                            "
                            label-width="80px"
                            label="排序："
                          >
                            <el-input-number
                              size="medium"
                              v-model="pic.sortNum"
                              :min="1"
                            ></el-input-number>
                          </el-form-item>
                        </div>

                        <el-button
                          v-if="
                            [1, 2, 5, 6].includes(grandson.composeType) || !grandson.composeType
                          "
                          :disabled="!grandson.composeType"
                          :style="{ opacity: grandson.composeType ? 1 : 0.4 }"
                          class="add-pic-button"
                          icon="el-icon-plus "
                          @click="addPic(grandson)"
                          size="medium"
                          style="color: #2861e2; border: 1px solid #2861e2; width: 100%"
                          >添加推荐产品</el-button
                        >
                      </el-form-item>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="area-item-content" style="margin-top: 10px">
            <el-form-item label="主标题：" label-width="70px">
              <el-input
                placeholder="请输入"
                v-model="area.title"
                maxlength="10"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="副标题：" label-width="70px">
              <el-input
                placeholder="请输入"
                v-model="area.subject"
                maxlength="15"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="探索更多：" label-width="70px">
              <el-select placeholder="请选择" v-model="area.recommendLinkValue" no-data-text="暂无">
              </el-select>
            </el-form-item>

            <el-button
              size="mini"
              type="primary"
              style="margin-bottom: 10px"
              @click="addGroup(area)"
              >增加分类</el-button
            >
            <el-tabs
              v-if="area.groupList.length"
              v-model="form.recommendDataList[index].currentGroup"
              type="card"
              closable
              @tab-remove="(e) => removeTab(e, area, 2)"
            >
              <el-tab-pane
                :key="grIndex"
                v-for="(grItem, grIndex) in form.recommendDataList[index].groupList"
                :label="grItem.recommendAreaGroupName"
                :name="String(grIndex)"
              >
              </el-tab-pane>
            </el-tabs>

            <div class="area-item-content-b">
              <template v-for="(grItem, grIndex) in area.groupList">
                <div
                  :key="grIndex"
                  :style="{
                    display: grIndex == area.currentGroup ? '' : 'none'
                  }"
                >
                  <el-form-item
                    :rules="rules.childrenTitle"
                    :prop="
                      'recommendDataList.' +
                      index +
                      '.groupList.' +
                      grIndex +
                      '.recommendAreaGroupName'
                    "
                    label-width="80px"
                    label="分类名称："
                  >
                    <el-input
                      placeholder="请输入"
                      v-model="grItem.recommendAreaGroupName"
                      maxlength="8"
                      show-word-limit
                    ></el-input>
                  </el-form-item>

                  <!-- <el-form-item label-width="80px" label="排序：">
                    <el-input-number size="medium" v-model="grItem.sortNum"></el-input-number>
                  </el-form-item> -->

                  <el-form-item
                    :rules="rules.composeType"
                    :prop="'recommendDataList.' + index + '.groupList.' + grIndex + '.composeType'"
                    label-width="80px"
                    label="样式库："
                  >
                    <div class="dropdown-container">
                      <input
                        class="input"
                        type="text"
                        v-model="grItem.composeTypeName"
                        @click.stop="(e) => toggleDropdown(e, grItem, index, grIndex)"
                        placeholder="请选择"
                        readonly="readonly"
                        autocomplete="off"
                      />
                      <i
                        class="dropdown-icon"
                        :class="{
                          'el-icon-arrow-down': !grItem.dropdownVisible,
                          'el-icon-arrow-up': grItem.dropdownVisible
                        }"
                      ></i>
                      <div
                        @click.stop="() => {}"
                        class="dropdown-list"
                        :style="{ top: grItem.top + 'px', left: grItem.left + 'px' }"
                        v-show="grItem.dropdownVisible"
                      >
                        <el-radio-group
                          v-model="grItem.composeTypeName"
                          @change="(e) => handleStyleChange(e, grItem, index, grIndex)"
                        >
                          <div
                            class="dropdown-item"
                            v-for="(layout, layIndex) in layoutList"
                            :key="layIndex"
                          >
                            <el-image
                              class="layout-image"
                              :src="
                                require(`../../../../assets/images/style${
                                  layoutStyle[layout.dictionaryValue - 1]
                                }.png`)
                              "
                            ></el-image>
                            <el-radio
                              @click.native="radioClick(grItem)"
                              :label="layout.dictionaryName"
                              >{{ layout.dictionaryName }}</el-radio
                            >
                          </div>
                        </el-radio-group>
                      </div>
                    </div>
                  </el-form-item>

                  <el-form-item
                    :rules="rules.recommendProduct"
                    :prop="'recommendDataList.' + index + '.groupList.' + grIndex + '.picList'"
                    label-width="80px"
                    label="推荐产品："
                  >
                    <div class="pic-item" v-for="(pic, picIndex) in grItem.picList" :key="picIndex">
                      <i
                        v-if="
                          grItem.composeType == 6 ||
                          ([1, 2, 5].includes(grItem.composeType) && grItem.picList.length > 3)
                        "
                        class="el-icon-delete pic-delete-icon"
                        @click="deletePic(pic, index, grIndex, picIndex)"
                      ></i>

                      <el-form-item
                        :rules="rules.pic"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.groupList.' +
                          grIndex +
                          '.picList.' +
                          picIndex +
                          '.picUrl'
                        "
                        label-width="80px"
                        label="产品图片："
                      >
                        <el-upload
                          class="avatar-uploader"
                          accept=".jpg, .png, .jpeg"
                          :action="actionUrl"
                          :show-file-list="false"
                          :on-success="(res, file) => handleAvatarSuccess(res, file, pic)"
                          :before-upload="beforeUpload"
                          list-type="picture-card"
                        >
                          <img
                            v-if="pic.picUrl"
                            :src="pic.picUrl"
                            :style="{ width: '150px', height: '150px' }"
                          />
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="upload-tip">
                          <i class="el-icon-warning"></i>
                          支持 1MB 以内 JPG、PNG 图片，{{
                            [1, 2, 5, 6].includes(grItem.composeType)
                              ? picTye[grItem.composeType][0]
                              : picTye[grItem.composeType][picIndex]
                          }}
                        </div>
                      </el-form-item>
                      <el-form-item label-width="80px" label="产品名称：">
                        <el-input
                          placeholder="请输入"
                          v-model="pic.picDec"
                          maxlength="15"
                          show-word-limit
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label-width="80px"
                        :rules="rules.linkType"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.groupList.' +
                          grIndex +
                          '.picList.' +
                          picIndex +
                          '.linkType'
                        "
                        label="链接类型："
                      >
                        <el-select v-model="pic.linkType">
                          <el-option label="套系产品ID" :value="1"></el-option>
                          <el-option label="自定义链接" :value="2"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        :rules="rules.link"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.groupList.' +
                          grIndex +
                          '.picList.' +
                          picIndex +
                          '.linkValue'
                        "
                        label-width="80px"
                        label="链接："
                      >
                        <el-input placeholder="请输入" v-model="pic.linkValue"></el-input>
                      </el-form-item>
                      <el-form-item
                        :rules="rules.sort"
                        :prop="
                          'recommendDataList.' +
                          index +
                          '.groupList.' +
                          grIndex +
                          '.picList.' +
                          picIndex +
                          '.sortNum'
                        "
                        label-width="80px"
                        label="排序："
                      >
                        <el-input-number
                          size="medium"
                          v-model="pic.sortNum"
                          :min="1"
                        ></el-input-number>
                      </el-form-item>
                    </div>

                    <el-button
                      v-if="[1, 2, 5, 6].includes(grItem.composeType) || !grItem.composeType"
                      :disabled="!grItem.composeType"
                      :style="{ opacity: grItem.composeType ? 1 : 0.4 }"
                      class="add-pic-button"
                      icon="el-icon-plus "
                      @click="addPic(grItem)"
                      size="medium"
                      style="color: #2861e2; border: 1px solid #2861e2; width: 100%"
                      >添加推荐产品</el-button
                    >
                  </el-form-item>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </el-form>
  </section>
</template>

<script>
import { pick } from 'lodash';
import { getDireactionListByKey } from '@/api/dictionary';
import {
  recommendJpgAreaList,
  recommendJpgAreaDelete,
  recommendJpgAreaSave,
  getClassificationList
} from '@/api/wechat';
import WeChatStyleWrapper from '@/components/wechat-style-jpg/index.vue';

export default {
  name: 'RecommendEdit',
  components: {
    WeChatStyleWrapper
  },
  props: ['topicId', 'recommendHasChildren'],
  data() {
    // 验证套系产品一级分类
    const checekRecommandId = (rule, value, callback) => {
      const reg = /^\+?[1-9]\d*$/;

      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error('套系产品ID必须为正整数'));
        } else {
          callback();
        }
      }, 1000);
    };

    const productValidate = (rule, value, callback) => {
      if (value.length) {
        callback();
      } else {
        callback(new Error('请配置推荐产品'));
      }
    };

    return {
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`,
      form: {
        recommendDataList: []
      },
      productRoles: {},
      layoutList: [],
      layoutStyle: ['011', '007', '005', '004', '010', '008'],
      loading: false,
      picTye: {
        1: ['无尺寸限制'],
        2: ['宽高比 3:4 建议尺寸 480×640px'],
        3: [
          '宽高比 1:1 建议尺寸 750×750px',
          '宽高比 3:4 建议尺寸 206×272px',
          '宽高比 3:4 建议尺寸 206×272px',
          '宽高比 3:4 建议尺寸 206×272px'
        ],
        4: [
          '宽高比 3:4 建议尺寸 328×434px',
          '宽高比 3:4 建议尺寸 328×434px',
          '宽高比 3:4 建议尺寸 328×434px',
          '宽高比 3:4 建议尺寸 328×434px'
        ],
        5: ['宽高比 4:3 建议尺寸 534×400px'],
        6: ['宽度750px，高度不限']
      },
      dropdownIndexList: [],
      rules: {
        title: { required: true, message: '请输入标题', trigger: 'blur' },
        childrenTitle: { required: true, message: '请输入分类名称', trigger: 'blur' },
        composeType: { required: true, message: '请选择样式', trigger: 'blur' },
        pic: { required: true, message: '请上传图片', trigger: 'blur' },
        link: { required: true, message: '请输入跳转链接', trigger: 'blur' },
        linkType: { required: true, message: '请选择链接类型', trigger: 'blur' },
        sort: { required: true, message: '请排序', trigger: 'blur' },
        recommendProduct: [
          { validator: productValidate, trigger: 'blur' },
          { required: true, message: '请配置推荐产品', trigger: 'blur' }
        ]
      },
      versionNum: '',
      scrollPostion: 0,
      currentInputTop: null
    };
  },
  computed: {
    hasChildrenIndex() {
      return this.form.recommendDataList.findIndex((el) => el.composeType == 1);
    }
  },
  watch: {
    'topicId': {
      handler(val) {
        if (val !== '') {
          this.getRecommendData();
        }
      },
      deep: true,
      immediate: true
    },
    'form.recommendDataList': {
      handler(val) {
        const param = val.some((el) => el.isTab);
        this.$emit('changeHasChildren', param);
      },
      deep: true
    }
  },

  async mounted() {
    await this.getLoadMoreList();
    window.addEventListener('click', this.closeDropDown);
    this.getLayouts();
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropDown);
  },
  methods: {
    // 上传图片
    toDown() {
      this.$nextTick(() => {
        this.$refs.altitude.scrollTop = this.$refs.altitude.scrollHeight;
      });
    },
    handleAvatarSuccess(res, file, handleObj) {
      if (res.success === false) {
        this.$message.error('上传图片失败');
        return;
      }
      handleObj.picUrl = res.data.http;
    },
    // 获取布局字典
    async getLayouts() {
      try {
        const params = {
          data: {
            dictionaryKey: 'recommend_style_jpg'
          }
        };
        const res = await getDireactionListByKey(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        this.layoutList = res;
      } catch (e) {}
    },
    // 获取推荐区域列表
    async getRecommendData() {
      this.loading = true;
      try {
        const params = {
          data: {
            isPublish: 0,
            topicId: this.topicId
          }
        };
        const res = await recommendJpgAreaList(params);

        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        res.forEach((item) => {
          delete item.recommendAreaId;

          item.isSave = true;

          if ((item.groupList || []).length) {
            item.isTab = false;
            item.currentGroup = '0';

            item.groupList.forEach((gr) => {
              delete gr.recommendAreaGroupId;
              delete gr.recommendAreaId;
              delete gr.recommendAreaPicId;
              gr.top = '';
              gr.left = '';
              gr.dropdownVisible = false;
              gr.composeTypeName = `样式${gr.composeType}`;
            });
          } else {
            item.isTab = true;
            item.currentChildren = '0';

            item.childrenList.forEach((child) => {
              delete child.recommendAreaId;
              child.currentGroup = '0';
              child.groupList.forEach((gr) => {
                delete gr.recommendAreaGroupId;
                delete gr.recommendAreaId;
                delete gr.recommendAreaPicId;
                gr.top = '';
                gr.left = '';
                gr.dropdownVisible = false;
                gr.composeTypeName = `样式${gr.composeType}`;
              });
            });
          }
        });
        this.form.recommendDataList = res;
        console.log(this.form.recommendDataList);
        this.versionNum = (res[0] || {}).versionNum || '';
      } catch (e) {
        this.loading = false;
      }
    },

    // 保存推荐列表
    async save() {
      this.$refs.recommendForm.validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              data: {
                isPublish: 0,
                list: this.form.recommendDataList,
                topicId: this.topicId
              }
            };

            params.data.list.forEach((area) => {
              delete area.recommendAreaId;
              if ((area.childrenList || []).length) {
                area.childrenList.forEach((child) => {
                  delete child.recommendAreaId;

                  if (child.groupList || [].length) {
                    child.groupList.forEach((gr) => {
                      delete gr.recommendAreaGroupId;
                      delete gr.recommendAreaId;
                      delete gr.recommendAreaPicId;
                    });
                  }
                });
              }

              if ((area.groupList || []).length) {
                area.groupList.forEach((gr) => {
                  delete gr.recommendAreaGroupId;
                  delete gr.recommendAreaId;
                  delete gr.recommendAreaPicId;
                });
              }
            });

            const res = await recommendJpgAreaSave(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.$message.success('保存给推荐区域成功');
            this.getRecommendData();
          } catch (e) {}
        }
        // this.$message.error('有必填项未填写！');
        return false;
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error('图片大小超过1M');
        return false;
      }
      return true;
    },

    /**
     *
     * @param {*} number  现有的数量
     * @param {*} total   目标数量
     * @param {*} index   现在对象索引
     */
    __dynamicRecommendProduct(number, total, handleObj) {
      const diffValue = total - number;

      let newArr = [...handleObj.picList];

      if (diffValue > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < diffValue; i++) {
          const item = {
            picUrl: '',
            picDec: '',
            linkType: null,
            linkValue: '',
            sortNum: 1
          };
          newArr.push(item);
        }
      } else {
        newArr = handleObj.picList.slice(0, total);
      }

      return newArr;
    },

    handleStyleChange(radioVal, handleObj, index, cIndex, grIndex) {
      const picLength = handleObj.picList ? handleObj.picList.length : 0;
      const radioValNum = Number(radioVal.slice(2));

      if (grIndex !== undefined) {
        this.form.recommendDataList[index].childrenList[cIndex].groupList[grIndex].composeType =
          radioValNum;
      } else {
        this.form.recommendDataList[index].groupList[cIndex].composeType = radioValNum;
      }

      let newArr = [];
      if (radioValNum === 6) {
        return;
      }

      if (radioValNum === 1 || radioValNum === 2 || radioValNum === 5) {
        const diffValue = 3 - picLength;

        if (diffValue > 0) {
          for (let i = 0; i < diffValue; i++) {
            const item = {
              picUrl: '',
              picDec: '',
              linkType: null,
              linkValue: '',
              sortNum: 1
            };
            newArr.push(item);
          }
        } else {
          return;
        }
        newArr = this.__dynamicRecommendProduct(picLength, 3, handleObj);
      }

      if (radioValNum === 3 || radioValNum === 4) {
        newArr = this.__dynamicRecommendProduct(picLength, 4, handleObj);
      }

      if (grIndex !== undefined) {
        this.form.recommendDataList[index].childrenList[cIndex].groupList[grIndex].picList = newArr;
        return;
      }

      if (cIndex !== undefined) {
        this.form.recommendDataList[index].groupList[cIndex].picList = newArr;
        return;
      }
    },
    addChildren(area, index) {
      const length = this.form.recommendDataList[index].childrenList.length;

      const child = {
        title: `分组${length + 1}`,
        subject: '',
        recommendLinkType: 0,
        recommendLinkValue: '',
        sortNum: 1,
        groupList: [
          {
            recommendAreaGroupName: '默认分组1',
            composeType: null,
            picList: [],
            left: '',
            top: '',
            dropdownVisible: false
          }
        ],
        currentGroup: '0'
      };
      this.form.recommendDataList[index].childrenList.push(child);

      this.form.recommendDataList[index].currentChildren = `${length}`;
    },
    addGroup(handleObj) {
      const length = handleObj.groupList.length;
      const item = {
        recommendAreaGroupName: `分类${length + 1}`,
        composeType: null,
        picList: [],
        // sortNum: 1,
        left: '',
        top: '',
        dropdownVisible: false
      };

      handleObj.groupList.push(item);
      handleObj.currentGroup = `${length}`;
    },
    removeTab(e, handleObj, type) {
      if (type == 1) {
        if (handleObj.childrenList.length == 1) {
          this.$message.error('推荐区至少有一个分组');
          return;
        }
      }

      if (type == 2) {
        if (handleObj.groupList.length == 1) {
          this.$message.error('分组至少有一个分类');
          return;
        }
      }

      this.$confirm('删除会导致编辑内容被清空，是否确认删除？').then(() => {
        if (type == 1) {
          if (handleObj.currentChildren == e) {
            handleObj.childrenList.forEach((el, index) => {
              if (index == e) {
                const nextTab =
                  handleObj.childrenList[index + 1] || handleObj.childrenList[index - 1];
                if (nextTab) {
                  handleObj.currentChildren = handleObj.childrenList[index + 1]
                    ? String(index + 1)
                    : String(index - 1);
                }
              }
            });
          } else {
            handleObj.currentChildren = String(handleObj.currentChildren - 1);
          }

          handleObj.childrenList = handleObj.childrenList.filter((el, index) => index != e);
        } else {
          if (handleObj.currentGroup == e) {
            handleObj.groupList.forEach((el, index) => {
              if (index == e) {
                const nextTab = handleObj.groupList[index + 1] || handleObj.groupList[index - 1];
                if (nextTab) {
                  handleObj.currentGroup = handleObj.groupList[index + 1]
                    ? String(index + 1)
                    : String(index - 1);
                }
              }
            });
          } else {
            handleObj.currentGroup = String(handleObj.currentGroup - 1);
          }

          handleObj.groupList = handleObj.groupList.filter((el, index) => index != e);
        }
      });
    },
    toggleDropdown(e, hanldeObj, index, childrenIndex, grIndex) {
      const el = document.elementFromPoint(e.clientX, e.clientY);

      hanldeObj.left = el.getBoundingClientRect().left;
      hanldeObj.top =
        document.documentElement.clientHeight - el.getBoundingClientRect().bottom < 300
          ? el.getBoundingClientRect().bottom - el.getBoundingClientRect().height - 270
          : el.getBoundingClientRect().bottom;

      hanldeObj.dropdownVisible = !hanldeObj.dropdownVisible;

      if (hanldeObj.dropdownVisible) {
        if (grIndex !== undefined) {
          this.dropdownIndexList = [index, childrenIndex, grIndex];
        } else {
          this.dropdownIndexList = [index, childrenIndex];
        }
        this.scrollPostion = document.querySelector('.recommentd-edit-content').scrollTop;
        this.currentInputTop = hanldeObj.top;
      } else {
        this.dropdownIndexList = [];
      }
    },
    closeDropDown() {
      if (!this.dropdownIndexList.length) return;
      const { 0: index, 1: childrenIndex, 2: grIndex } = this.dropdownIndexList;

      if (this.dropdownIndexList.length == 3) {
        this.form.recommendDataList[index].childrenList[childrenIndex].groupList[
          grIndex
        ].dropdownVisible = false;
      } else {
        this.form.recommendDataList[index].groupList[childrenIndex].dropdownVisible = false;
      }

      this.dropdownIndexList = [];
    },
    addPic(handleObj) {
      const item = {
        picUrl: '',
        picDec: '',
        linkType: null,
        linkValue: '',
        sortNum: 1
      };
      handleObj.picList.push(item);
    },
    async getLoadMoreList() {
      const reqObj = {
        data: {}
      };

      const res = await getClassificationList(reqObj);
    },

    deleteArea(area, areaIndex) {
      this.$confirm('删除会导致推荐区域编辑内容被清空，是否确认删除？')
        .then(async () => {
          this.form.recommendDataList = this.form.recommendDataList.filter(
            (_, index) => index !== areaIndex
          );
          // this.$message.success('删除成功');
          return;

          // const { recommendAreaId } = area;
          // try {
          //   this.loading = true;
          //   const params = {
          //     data: {
          //       recommendAreaId
          //     }
          //   };
          //   const res = await recommendAreaDelete(params);
          //   this.loading = false;
          //   if (res.success === false) {
          //     this.$message.error(res.description);
          //     return;
          //   }
          //   this.form.recommendDataList = this.form.recommendDataList.filter(
          //     (item) => item.recommendAreaId !== recommendAreaId
          //   );
          // } catch (e) {
          //   this.loading = false;
          // }
        })
        .catch();
    },
    deletePic(pic, index, childrenIndex, grIndex, picIndex) {
      // if ([1, 2, 5].includes(pic.composeType)) {
      //   this.$message.error('该样式最少有三个产品');
      //   return;
      // }

      if (picIndex !== undefined) {
        this.form.recommendDataList[index].childrenList[childrenIndex].groupList[grIndex].picList =
          this.form.recommendDataList[index].childrenList[childrenIndex].groupList[
            grIndex
          ].picList.filter((el, eIndex) => eIndex !== picIndex);
      } else {
        this.form.recommendDataList[index].groupList[childrenIndex].picList =
          this.form.recommendDataList[index].groupList[childrenIndex].picList.filter(
            (el, eIndex) => eIndex !== grIndex
          );
      }
    },
    radioClick(handleObj) {
      this.dropdownIndexList = [];
      handleObj.dropdownVisible = false;
    },
    contentScroll(e) {
      if (!this.dropdownIndexList.length) return;
      const currentScrollPostion = e.target.scrollTop;
      const { 0: index, 1: childrenIndex, 2: grIndex } = this.dropdownIndexList;
      const distance = currentScrollPostion - this.scrollPostion;

      if (this.dropdownIndexList.length == 3) {
        this.form.recommendDataList[index].childrenList[childrenIndex].groupList[grIndex].top =
          this.currentInputTop - distance;
      } else {
        this.form.recommendDataList[index].groupList[childrenIndex].top =
          this.currentInputTop - distance;
      }
    }
  }
};
</script>

<style scoped lang="less">
.recommentd-edit-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;

  .area-item {
    .area-item-title {
      font-size: 24px;
      padding-left: 14px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      // position: relative;

      .area-delete-icon {
        // position: absolute;
        margin-right: 22px;
        cursor: pointer;
        font-size: 16px;
        color: #454545;
        // top: -16px;
        // right: -22px;
      }
    }

    .area-item-title::before {
      content: '';
      background: #111111;
      border-radius: 0 3px 3px 0;
      height: 28px;
      width: 4px;
      display: inline-block;
      position: relative;
      top: 0px;
      left: -14px;
    }

    .area-item-sort {
      margin-top: 20px;
    }

    .area-item-content {
      border: 1px solid #ededed;
      padding: 24px;

      ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background: #f8f8f8;
      }

      ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
      }

      ::v-deep .el-tabs__item {
        border: none;
      }

      ::v-deep .el-tabs--card > .el-tabs__header {
        border: none;
      }
    }

    .area-item-content-b {
      background: #f8f8f8;
      padding: 20px;

      .dropdown-container {
        position: relative;
        width: 300px;

        .dropdown-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 8px;
          color: #9a9d9e;
          font-size: 14px;
        }

        // .dropdown-icon-transition {
        //   transition: transform;
        //   transform: rotate(180deg) translateY(-50%);
        // }

        input[type='text'] {
          width: 100%;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          color: #606266;
          outline: 0;
          padding: 0 15px;
          height: 28px;
          line-height: 28px;
          cursor: pointer;
        }

        .dropdown-list {
          position: fixed;
          right: 0;

          justify-content: flex-start;
          border-radius: 4px;
          border: 1px solid #e4e7ed;
          box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
          background: white;
          border-top: none;
          z-index: 1000;
          padding: 20px 0 0 20px;
          box-sizing: border-box;
          width: 520px;
          height: 270px;
          align-content: start;
          overflow-x: hidden;
          overflow-y: scroll;

          ::v-deep .el-radio-group {
            display: flex;
            flex-wrap: wrap;
          }

          ::v-deep .el-radio {
            text-align: center;
            margin-top: 10px;
          }

          .dropdown-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 18px;
            margin-right: 18px;
          }

          .layout-image {
            width: 80px;
            height: 80px;
          }

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #e0e3e7;
          }

          &::-webkit-scrollbar-track {
            display: none;
          }
        }
      }

      .pic-item {
        padding: 30px 20px;
        border-radius: 4px;
        background: #fff;
        position: relative;

        .avatar-uploader {
          ::v-deep .el-upload {
            border: 1px dashed #e0e0e0;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background: #fff;
          }

          // ::v-deep .el-upload:hover {
          //   border-color: #409eff;
          // }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            background: #fff;
          }
        }

        .upload-tip {
          font-size: 12px;
          color: #999;
        }

        .pic-delete-icon {
          position: absolute;
          cursor: pointer;
          font-size: 16px;
          color: #454545;
          top: 16px;
          right: 22px;
        }
      }

      .pic-item + .pic-item {
        margin-top: 10px;
      }

      .pic-item + .add-pic-button {
        margin-top: 10px;
      }
    }
  }

  .area-item + .area-item {
    margin-top: 50px;
  }

  ::v-deep .el-tabs__header {
    margin: unset;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    color: #454545;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: #000000;
  }

  .form-content-wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 50px 15px 20px 15px;
    margin-bottom: 15px;

    .delete-area {
      position: absolute;
      right: 20px;
      top: 15px;

      .delete {
        cursor: pointer;
      }
    }
  }
}

.text-black-a {
  color: #363f47;
}
.recommend-list::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.recommend-list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.recommend-list::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.recommend-list-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f5f5f5;
  padding: 15px;
  margin-bottom: 20px;

  .delete {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    cursor: pointer;
  }
}

.recommend-list-item-b {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;

  .delete {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    cursor: pointer;
  }
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
