<template>
  <section class="recommentd-edit-content" ref="altitude" v-loading="loading">
    <el-form label-width="180px" :model="form" ref="recommendForm">
      <div
        v-for="(area, index) in form.recommendDataList"
        :key="`area${index}`"
        class="form-content-wrapper"
      >
        <div class="delete-area">
          <i class="el-icon-delete delete" @click="handleDeleteArea(area, index)"></i>
        </div>
        <el-form-item
          label="推荐标题："
          :prop="'recommendDataList.' + index + '.title'"
          :style="{ width: '100%' }"
        >
          <el-input placeholder="请填写推荐标题" maxlength="20" v-model="area.title" />
        </el-form-item>
        <el-form-item label="小标题：" :prop="'recommendDataList.' + index + '.subject'">
          <el-input placeholder="请填写小标题" maxlength="20" v-model="area.subject" />
        </el-form-item>
        <el-form-item
          label="套系产品分类ID："
          :prop="'recommendDataList.' + index + '.categoryId'"
          :rules="{ required: true, message: '请输入套系产品分类ID：', trigger: 'blur' }"
        >
          <el-input
            type="number"
            placeholder="请输入套系产品分类ID"
            v-model="area.categoryId"
            @keyup.native="proving1($event)"
            min="1"
          />
        </el-form-item>
        <el-form-item label="排序：" :prop="'recommendDataList.' + index + '.sortNum'">
          <el-input-number
            v-model="area.sortNum"
            :min="1"
            :max="9999"
            label="请输入排序数字，数字越小越靠前"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="推荐样式："
          :prop="'recommendDataList.' + index + '.composeType'"
          :rules="areaRules.composeType"
        >
          <div style="overflow: auto" class="recommend-list">
            <el-row style="min-width: 600px">
              <el-col
                :md="8"
                :sm="8"
                :lg="4"
                v-for="(layout, sIndex) in layoutList"
                :key="`s${sIndex}`"
              >
                <el-radio-group
                  v-model="area.composeType"
                  @change="(e) => handleStyleChange(index, e)"
                >
                  <div class="layout-wrapper">
                    <we-chat-style-wrapper :index="parseInt(layout.dictionaryValue)" />

                    <el-radio :label="Number(layout.dictionaryValue)">{{
                      layout.dictionaryName
                    }}</el-radio>
                  </div>
                </el-radio-group>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <div
          class="recommend-list-item"
          v-for="(product, pIndex) in area.products"
          :key="`product${pIndex}`"
        >
          <i
            class="el-icon-delete delete"
            v-show="area.composeType == 1 || area.composeType == 7 || area.composeType == 8"
            @click="handleDeleteProduct(index, pIndex)"
          ></i>

          <el-form-item
            label="图片："
            :prop="'recommendDataList.' + index + '.products.' + pIndex + '.picUrl'"
            :rules="{ required: true, message: '请选择图片', trigger: 'blur' }"
          >
            <el-upload
              class="avatar-uploader"
              accept=".jpg, .png, .jpeg"
              :action="actionUrl"
              :show-file-list="false"
              :on-success="(res, file) => handleAvatarSuccess(res, file, index, pIndex)"
              :before-upload="beforeUpload"
            >
              <img v-if="product.picUrl" :src="product.picUrl" :style="{ width: '150px' }" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="upload-tip">
              <i class="el-icon-info"></i>
              只能上传jpg/png格式文件，文件不能超过50M，建议尺寸{{
                widthHeightTip(area.composeType, pIndex)
              }}
            </div>
          </el-form-item>
          <el-form-item
            label="标题："
            :prop="'recommendDataList.' + index + '.products.' + pIndex + '.productName'"
          >
            <el-input type="text" maxlength="20" v-model="product.productName" />
          </el-form-item>
          <el-form-item
            label="自定义链接："
            :prop="'recommendDataList.' + index + '.products.' + pIndex + '.productId'"
            :rules="{ required: true, message: '请输入自定义链接', trigger: 'blur' }"
          >
            <el-input v-model="product.productId" />
          </el-form-item>
          <el-form-item
            label="排序："
            :prop="'recommendDataList.' + index + '.products.' + pIndex + '.sortNum'"
          >
            <el-input-number
              v-model="product.sortNum"
              :min="1"
              :max="9999"
              :disabled="area.composeType !== 1 && area.composeType !== 7 && area.composeType !== 8"
              label="请输入排序数字，数字越小越靠前"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="footer">
          <el-button
            type="primary"
            @click="handleAddProduct(area, index)"
            v-show="area.composeType == 1 || area.composeType == 7 || area.composeType == 8"
            >增加推荐产品</el-button
          >
        </div>
      </div>
    </el-form>
  </section>
</template>

<script>
import { pick } from 'lodash';
import { getDireactionListByKey } from '@/api/dictionary';
import { recommendAreaList, recommendAreaDelete, recommendAreaSave } from '@/api/wechat';
import WeChatStyleWrapper from '@/components/wechat-style/index.vue';

export default {
  name: 'RecommendEdit',
  components: {
    WeChatStyleWrapper
  },
  props: ['topicId'],
  data() {
    // 验证套系产品一级分类
    const checekRecommandId = (rule, value, callback) => {
      const reg = /^\+?[1-9]\d*$/;

      setTimeout(() => {
        if (!reg.test(value)) {
          callback(new Error('套系产品ID必须为正整数'));
        } else {
          callback();
        }
      }, 1000);
    };

    return {
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`,
      form: {
        recommendDataList: []
      },
      areaRules: {
        subject: [{ required: true, message: '请输入推荐小标题', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '请输入推荐小标题', trigger: 'blur' },
          { validate: checekRecommandId, trigger: 'blur' }
        ],
        composeType: [{ required: true, message: '请选择推荐样式', trigger: 'change' }]
      },
      productRoles: {},
      layoutList: [],
      loading: false,
      picTye: {
        1: [{ id: 1, des: '375px*325.5px' }],
        2: [
          { id: 1, des: '343px*343px' },
          { id: 1, des: '343px*343px' }
        ],
        3: [
          { id: 1, des: '120px*180px' },
          { id: 2, des: '215px*180px' },
          { id: 3, des: '343px*180px' }
        ],
        4: [
          { id: 1, des: '167.5px*230px' },
          { id: 2, des: '167.5px*230px' },
          { id: 3, des: '167.5px*230px' },
          { id: 4, des: '167.5px*230px' }
        ],
        5: [
          { id: 1, des: '343px*200px' },
          { id: 2, des: '109px*164px' },
          { id: 3, des: '109px*164px' },
          { id: 4, des: '109px*164px' }
        ],
        6: [
          { id: 1, des: '120px*180px' },
          { id: 2, des: '215px*180px' },
          { id: 3, des: '109px*164px' },
          { id: 4, des: '109px*164px' },
          { id: 5, des: '109px*164px' }
        ],
        7: [{ id: 1, des: '324px*432px' }],
        8: [{ id: 1, des: '984px*256px' }]
      }
    };
  },
  computed: {
    widthHeightTip() {
      const that = this;
      return function (styleType, pIndex) {
        const arrayObj = pick(this.picTye, [styleType]);
        if (styleType === 1) {
          return arrayObj[styleType][0].des;
        }
        return arrayObj[styleType][pIndex] ? arrayObj[styleType][pIndex].des : '';
      };
    }
  },
  watch: {
    topicId: {
      handler(val) {
        if (val !== '') {
          this.getRecommendData();
        }
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    this.getLayouts();
  },
  methods: {
    // 上传图片
    toDown() {
      this.$nextTick(() => {
        this.$refs.altitude.scrollTop = this.$refs.altitude.scrollHeight;
      });
    },
    handleAvatarSuccess(res, file, index, pIndex) {
      if (res.success === false) {
        this.$message.error('上传图片失败');
        return;
      }
      this.form.recommendDataList[index].products[pIndex].picUrl = res.data.http;
    },
    proving1(e) {
      const keynum = window.event ? e.keyCode : e.which; // 获取键盘码
      const keychar = String.fromCharCode(keynum); // 获取键盘码对应的字符
      if (keynum === 189 || keynum === 190 || keynum === 109) {
        this.$message.warning('禁止输入小数以及负数');
        e.target.value = ' ';
      }
    },
    // 获取布局字典
    async getLayouts() {
      try {
        const params = {
          data: {
            dictionaryKey: 'introduction_sample'
          }
        };
        const res = await getDireactionListByKey(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        this.layoutList = res;
      } catch (e) {}
    },
    // 获取推荐区域列表
    async getRecommendData() {
      this.loading = true;
      try {
        const params = {
          data: {
            isPublish: 0,
            topicId: this.topicId
          }
        };
        const res = await recommendAreaList(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        res.forEach((item) => {
          item.isSave = true;
        });
        this.form.recommendDataList = res;
      } catch (e) {
        this.loading = false;
      }
    },
    // 新增产品
    handleAddProduct(area, index) {
      const { composeType } = area;
      if (composeType !== 1) {
        if (composeType === 2) {
          if (area.products.length >= 2) {
            this.$message.error('样式2最多新增2个产品');
            return;
          }
        }
        if (composeType === 3) {
          if (area.products.length >= 3) {
            this.$message.error('样式3最多新增3个产品');
            return;
          }
        }
        if (composeType === 4) {
          if (area.products.length >= 4) {
            this.$message.error('样式4最多新增4个产品');
            return;
          }
        }
        if (composeType === 5) {
          if (area.products.length >= 4) {
            this.$message.error('样式5最多新增4个产品');
            return;
          }
        }
        if (composeType === 6) {
          if (area.products.length >= 5) {
            this.$message.error('样式6最多新增5个产品');
            return;
          }
        }
        if (composeType === 7) {
          if (area.products.length >= 7) {
            this.$message.error('样式7最多新增7个产品');
            return;
          }
        }
        if (composeType === 8) {
          if (area.products.length >= 4) {
            this.$message.error('样式8最多新增4个产品');
            return;
          }
        }
      }

      const item = {
        recommendAreaId: '',
        picUrl: '',
        productName: '',
        sortNum: 1,
        productId: '',
        composeType: 10,
        products: [],
        isSave: false
      };
      this.form.recommendDataList[index].products.push(item);
    },
    async handleDeleteArea(area, areaIndex) {
      if (!area.isSave) {
        this.form.recommendDataList = this.form.recommendDataList.filter(
          (_, index) => index !== areaIndex
        );
        this.$message.success('删除成功');
        return;
      }
      const { recommendAreaId } = area;
      try {
        this.loading = true;
        const params = {
          data: {
            recommendAreaId
          }
        };
        const res = await recommendAreaDelete(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.form.recommendDataList = this.form.recommendDataList.filter(
          (item) => item.recommendAreaId !== recommendAreaId
        );
      } catch (e) {
        this.loading = false;
      }
    },
    // 删除产品
    handleDeleteProduct(areaIndex, productIndex) {
      this.form.recommendDataList[areaIndex].products = this.form.recommendDataList[
        areaIndex
      ].products.filter((_, index) => index !== productIndex);
    },
    // 保存推荐列表
    async save() {
      this.$refs.recommendForm.validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              data: {
                isPublish: 0,
                list: this.form.recommendDataList,
                topicId: this.topicId
              }
            };
            // 判断区域是否有product
            const productsIsEmpty = this.form.recommendDataList.some(
              (item) => item.products.length === 0
            );

            if (productsIsEmpty) {
              this.$message.error('请检查推荐区域下是否包含产品');
              return;
            }
            const res = await recommendAreaSave(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.$message.success('保存给推荐区域成功');
            this.getRecommendData();
          } catch (e) {}
        }
        return false;
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 50;
      if (!isLt1M) {
        this.$message.error('图片大小超过50M');
        return false;
      }
      return true;
    },

    /**
     *
     * @param {*} number  现有的数量
     * @param {*} total   目标数量
     * @param {*} index   现在对象索引
     */
    __dynamicRecommendProduct(number, total, index) {
      const diffValue = total - number;

      if (diffValue > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < diffValue; i++) {
          const item = {
            recommendAreaId: '',
            picUrl: '',
            productName: '',
            sortNum: i + number + 1,
            productId: '',
            composeType: 10,
            products: [],
            isSave: false
          };
          console.log(i);
          this.form.recommendDataList[index].products.push(item);
        }
      } else {
        const newProducts = this.form.recommendDataList[index].products.slice(0, total);
        this.form.recommendDataList[index].products = newProducts;
      }

      console.log(this.form.recommendDataList[index]);
    },

    handleStyleChange(index, radioVal) {
      const productsLength = this.form.recommendDataList[index].products
        ? this.form.recommendDataList[index].products.length
        : 0;
      if (radioVal === 1 || radioVal === 8 || radioVal === 7) {
        return;
      }
      if (radioVal === 2) {
        this.__dynamicRecommendProduct(productsLength, 2, index);
      }
      if (radioVal === 3) {
        this.__dynamicRecommendProduct(productsLength, 3, index);
      }
      if (radioVal === 4) {
        this.__dynamicRecommendProduct(productsLength, 4, index);
      }
      if (radioVal === 5) {
        this.__dynamicRecommendProduct(productsLength, 4, index);
      }
      if (radioVal === 6) {
        this.__dynamicRecommendProduct(productsLength, 5, index);
      }
    }
  }
};
</script>

<style scoped lang="less">
.recommentd-edit-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;

  .form-content-wrapper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 50px 15px 20px 15px;
    margin-bottom: 15px;

    .delete-area {
      position: absolute;
      right: 20px;
      top: 15px;

      .delete {
        cursor: pointer;
      }
    }
  }
}
.recommend-list::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.recommend-list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.recommend-list::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.recommend-list-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f5f5f5;
  padding: 15px;
  margin-bottom: 20px;

  .delete {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    cursor: pointer;
  }
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #000;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #fff;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #fff;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.upload-tip {
  font-size: 12px;
  color: #999;
}
</style>
