<template>
  <section class="banner-list-wrapper" ref="altitude" v-loading="loading">
    <el-empty v-if="form.bannerList.length === 0" />

    <div style="margin: 18px 0" v-if="form.bannerList.length > 0">
      <span style="color: #606266; font-size: 14px; font-weight: bold">图片展示样式：</span>
      <el-radio-group v-model="layoutType">
        <el-radio :label="1">横屏</el-radio>
        <el-radio :label="2">竖屏</el-radio>
      </el-radio-group>
    </div>

    <el-form
      label-width="84px"
      :model="form"
      ref="bannerForm"
      v-if="form.bannerList.length > 0"
      :rules="rules"
    >
      <section
        class="banner-list-item"
        v-for="(banner, index) in form.bannerList"
        :key="banner.bannerId"
      >
        <div class="delete-area">
          <i class="el-icon-delete delete" @click="handleDeleteBanner(banner, index)"></i>
        </div>
        <el-form-item
          label="图片或视频："
          :prop="'bannerList.' + index + '.picUrl'"
          :rules="{
            required: true,
            message: '请上传图片',
            trigger: 'blur'
          }"
          label-width="120px"
        >
          <el-upload
            class="avatar-uploader"
            accept=".jpg,.png,.jpeg,.mp4,.avi,.mov,.mkv"
            :action="actionUrl"
            :show-file-list="false"
            :http-request="(event) => handleOnProgress(event, index)"
            :before-upload="beforeUpload"
          >
            <img
              v-if="checkMediaType(banner.picUrl) == 'image'"
              :src="banner.picUrl"
              :style="{ width: '150px' }"
            />

            <video
              width="150"
              :src="banner.picUrl"
              :autoplay="false"
              v-if="checkMediaType(banner.picUrl) == 'video'"
            />

            <i v-if="!banner.picUrl" class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <div class="upload-tip">
            <i class="el-icon-info"></i>
            图片支持jpg/png格式，视频支持mp4/avi格式，文件不能超过5M，横屏尺寸375px*375px，竖屏比例1:2
          </div>
        </el-form-item>

        <el-form-item label="自定义链接：" :prop="'bannerList.' + index + '.productId'">
          <el-input type="url" v-model="banner.productId" />
        </el-form-item>
        <el-form-item label="排序：" prop="sortNum">
          <el-input-number
            v-model="banner.sortNum"
            :min="1"
            :max="9999"
            label="排序"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </section>
    </el-form>
  </section>
</template>

<script>
import { getBannerOrNavigationList, bannerSave, deleteBanner } from '@/api/wechat';
import ossUploadFile from '@/oss';

export default {
  name: 'BannerEdit',
  props: ['topicId'],
  data() {
    return {
      loading: false,
      actionUrl: `#`,
      imageUrl: '',
      form: {
        bannerList: []
      },
      rules: {},
      layoutType: 1
    };
  },
  watch: {
    topicId: {
      handler(val) {
        if (val !== '') {
          this.getBannerList();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toDown() {
      this.$nextTick(() => {
        this.$refs.altitude.scrollTop = this.$refs.altitude.scrollHeight;
      });
    },
    // 上传图片
    async handleOnProgress(event, bannerIndex) {
      try {
        const res = await ossUploadFile(event.file);
        if (res) this.form.bannerList[bannerIndex].picUrl = res;
      } catch (err) {
        console.log(err);
        this.$message.error('上传图片失败');
      }
    },
    // 删除banner
    async handleDeleteBanner(banner, bannerIndex) {
      if (!banner.bannerId) {
        this.form.bannerList = this.form.bannerList.filter((_, index) => index !== bannerIndex);
        this.$message.success('删除成功');
        return;
      }
      const { bannerId } = banner;
      const params = {
        data: {
          bannerId
        }
      };
      try {
        const res = await deleteBanner(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.form.bannerList = this.form.bannerList.filter((item) => item.bannerId !== bannerId);
        this.$message.success('删除成功');
      } catch (e) {
        console.log(e);
      }
    },
    // 获取 banner 列表
    async getBannerList() {
      this.loading = true;
      try {
        const params = {
          data: {
            siteId: 1,
            status: 2, // 1发布   2 备份草稿
            topicId: this.topicId
          }
        };

        const res = await getBannerOrNavigationList(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        this.form.bannerList = res || [];
        this.layoutType = Number(((res || [])[0] || {}).layoutType || 1);
      } catch (e) {
        this.loading = false;
      }
    },
    save() {
      if (this.form.bannerList.length > 0) {
        let flag = false;

        this.form.bannerList.forEach((el) => {
          if (
            el.productId &&
            !(
              el.productId.startsWith('/') ||
              el.productId.startsWith('http') ||
              !Number.isNaN(Number(el.productId))
            )
          ) {
            flag = true;
          }
        });

        if (flag) {
          this.$message.error('自定义链接只能填写数字或链接');
          return;
        }

        this.$refs.bannerForm.validate(async (valid) => {
          if (valid) {
            const params = {
              data: {
                siteId: 1,
                status: 2,
                banners: this.form.bannerList.map((el) => ({ ...el, layoutType: this.layoutType })),
                topicId: this.topicId
              }
            };

            const res = await bannerSave(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.$message.success('保存轮播图成功');
          }
          return false;
        });
      } else {
        this.$message.error('请添加轮播图');
      }
    },
    // 发布banner
    publish() {
      if (this.form.bannerList.length > 0) {
        this.$refs.bannerForm.validate(async (valid) => {
          if (valid) {
            const params = {
              data: {
                siteId: 1,
                status: 1,
                banners: this.form.bannerList.map((el) => ({ ...el, layoutType: this.layoutType })),
                topicId: this.topicId
              }
            };
            const res = await bannerSave(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.getBannerList();
            this.$message.success('发布成功');
          }
          return false;
        });
      } else {
        this.$message.error('请添加轮播图');
      }
    },
    beforeUpload(file) {
      console.log(file.size);
      const isLt1M = file.size / 1024 / 1024 < 5;

      if (!isLt1M) {
        this.$message.error('图片大小超过5M');
        return false;
      }

      return true;
    },
    checkMediaType(url) {
      if (!url) return 'unknown';

      // 提取 URL 的文件扩展名
      const extension = url.split('?')[0].split('.').pop().toLowerCase();

      // 支持的图片文件扩展名列表
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

      // 支持的视频文件扩展名列表
      const videoExtensions = ['mp4', 'avi', 'mov', 'mkv'];

      if (imageExtensions.includes(extension)) {
        return 'image';
      } else if (videoExtensions.includes(extension)) {
        return 'video';
      } else {
        return 'unknown';
      }
    }
  }
};
</script>

<style scoped lang="less">
.banner-list-wrapper {
  height: 100%;
  overflow: auto;

  .banner-list-item {
    position: relative;
    background: #f5f5f5;
    padding: 15px;
    margin-bottom: 20px;

    .delete-area {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 16px;
      height: 16px;
      z-index: 999;

      .delete {
        cursor: pointer;
      }
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #000;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #fff;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #fff;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload-tip {
    font-size: 12px;
    color: #999;
  }
}
</style>
