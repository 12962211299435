import request from '@/utils/request';

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);

  return request({
    url: 'misc-web-api/common/file/uploadImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function saveSettings(data) {
  return request({
    url: 'misc-web-api/admin/banner/save',
    method: 'POST',
    data: data
  });
}

export function getSettings(data) {
  return request({
    url: 'misc-web-api/admin/banner/getList',
    method: 'POST',
    data: data
  });
}

export function resetSettings(data) {
  return request({
    url: 'misc-web-api/admin/banner/back',
    method: 'POST',
    data: data
  });
}

export default {};
