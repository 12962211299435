var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"altitude",staticClass:"banner-list-wrapper"},[(_vm.form.bannerList.length === 0)?_c('el-empty'):_vm._e(),(_vm.form.bannerList.length > 0)?_c('el-form',{ref:"navigationFrom",attrs:{"label-width":"150px","model":_vm.form}},_vm._l((_vm.form.bannerList),function(banner,index){return _c('section',{key:banner.bannerId,staticClass:"banner-list-item"},[_c('div',{staticClass:"delete-area"},[_c('i',{staticClass:"el-icon-delete delete",on:{"click":function($event){return _vm.handleDeleteBanner(banner, index)}}})]),_c('el-form-item',{attrs:{"label":"图片：","prop":'bannerList.' + index + '.picUrl',"rules":{
          required: true,
          message: '请上传图片',
          trigger: 'blur'
        }}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.actionUrl,"accept":".jpg,.png,.jpeg","show-file-list":false,"on-success":(res, file) => _vm.handleAvatarSuccess(res, file, index),"before-upload":_vm.beforeUpload}},[(banner.picUrl)?_c('img',{style:({ width: '150px' }),attrs:{"src":banner.picUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"upload-tip"},[_c('i',{staticClass:"el-icon-info"}),_vm._v(" 只能上传jpg/png格式文件，文件不能超过50M，建议尺寸40px*40px ")])],1),_c('el-form-item',{attrs:{"label":"导航名称：","prop":'bannerList.' + index + '.name',"rules":{
          required: true,
          message: '请输入导航名称',
          trigger: 'blur'
        }}},[_c('el-input',{attrs:{"type":"url"},model:{value:(banner.name),callback:function ($$v) {_vm.$set(banner, "name", $$v)},expression:"banner.name"}})],1),_c('el-form-item',{attrs:{"label":"套系产品分类ID：","rules":{
          required: true,
          message: '请输入套系产品分类ID',
          trigger: 'blur'
        },"prop":'bannerList.' + index + '.productCategoryId'}},[_c('el-input',{attrs:{"type":"url"},model:{value:(banner.productCategoryId),callback:function ($$v) {_vm.$set(banner, "productCategoryId", $$v)},expression:"banner.productCategoryId"}})],1),_c('el-form-item',{attrs:{"label":"排序：","prop":"sortNum"}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"min":1,"max":9999,"label":"排序"},model:{value:(banner.sortNum),callback:function ($$v) {_vm.$set(banner, "sortNum", $$v)},expression:"banner.sortNum"}})],1)],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }